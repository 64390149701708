import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import CatalogListing from "components/CatalogListing"
import CatalogDetail from "components/CatalogDetail"
import Signup from "components/Signup"

const CustomArrow = ({ className, iconSrc, ...rest }) => {
  return (
    <button className={`carousel-arrow ${className} p-1`} {...rest}>
      <img alt="arrow" className="w-12 h-20" src={iconSrc} />
    </button>
  )
}

const Catalog = () => {
  const data = useStaticQuery(graphql`
    query {
      allCatalogJson {
        edges {
          node {
            title
            image
            description
          }
        }
      }
      allImageSharp {
        edges {
          node {
            fixed {
              originalName
            }
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      allFile(filter: { name: { in: ["navigate-next", "navigate-before"] } }) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `)

  const works = data.allCatalogJson.edges.map(edge => edge.node)

  // Construct dictionary of image name -> fluid
  const images = {}
  data.allImageSharp.edges.forEach(
    edge => (images[edge.node.fixed.originalName] = edge.node.fluid)
  )

  // Carousel button SVGs
  const buttons = {}
  data.allFile.edges.forEach(
    edge => (buttons[edge.node.name] = edge.node.publicURL)
  )

  // Default to latest work
  const [selectedWork, setSelectedWork] = useState(works[0])

  const responsive = {
    "2xl": {
      breakpoint: { max: 9999, min: 1536 },
      items: 6,
      slidesToSlide: 6,
    },
    xl: {
      breakpoint: { max: 1536, min: 1280 },
      items: 5,
      slidesToSlide: 5,
    },
    lg: {
      breakpoint: { max: 1280, min: 1024 },
      items: 4,
      slidesToSlide: 4,
    },
    md: {
      breakpoint: { max: 1024, min: 580 },
      items: 3,
      slidesToSlide: 3,
    },
    sm: {
      breakpoint: { max: 580, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  }

  return (
    <div className="mx-auto relative mt-12">
      <div id="carousel">
        <Carousel
          swipeable={true}
          draggable={true}
          responsive={responsive}
          autoPlay={false}
          containerClass="pt-36 -mt-36 pb-36 -mb-36"
          itemClass="py-2"
          sliderClass="z-40"
          customLeftArrow={
            <CustomArrow
              className="left-3"
              iconSrc={buttons["navigate-before"]}
            />
          }
          customRightArrow={
            <CustomArrow
              className="right-3"
              iconSrc={buttons["navigate-next"]}
            />
          }
        >
          {works.map((work, index) => (
            <CatalogListing
              key={index}
              title={work.title}
              fluid={images[work.image]}
              description={work.description}
              onClick={() => setSelectedWork(work)}
            />
          ))}
        </Carousel>
      </div>
      <div className="flex flex-col sm:grid sm:grid-cols-3 sm:gap-4 mt-16">
        <div className="col-span-3 md:col-span-2">
          <CatalogDetail work={selectedWork} />
        </div>
        <div className="sm:mt-24 px-4 pb-4 col-span-3 md:ml-0 md:col-span-1">
          <Signup />
        </div>
      </div>
    </div>
  )
}

export default Catalog
