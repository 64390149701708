import React from "react"

import Background from "components/Background"
import Layout from "components/Layout"
import SEO from "components/SEO"
import Catalog from "components/Catalog"
import "../style/style.css"

const IndexPage = () => (
  <>
    <Layout>
      <SEO title="Home" />
      <Catalog />
    </Layout>
    <Background bandClass="bg-catalog-top" />
  </>
)

export default IndexPage
