import React from "react"
import { Link } from "gatsby"
import Markdown from "markdown-to-jsx"

import TextSection from "components/TextSection"

const CatalogDetail = ({ work }) => (
  <TextSection showPen={true} header={work.title}>
    <Markdown
      options={{
        overrides: {
          Link: {
            component: Link,
          },
        },
      }}
    >
      {work.description}
    </Markdown>
  </TextSection>
)

export default CatalogDetail
