import React from "react"
import Img from "gatsby-image"
import { useSpring, animated } from "react-spring"

import Markdown from "markdown-to-jsx"

const calc = event => {
  const rect = event.nativeEvent.target.getBoundingClientRect()
  const elementX = event.clientX - rect.left
  const elementY = event.clientY - rect.top

  return [
    (elementY - rect.height / 2) / 15,
    -(elementX - rect.width / 2) / 4,
    1.05,
  ]
}
const trans = (x, y, s) =>
  `perspective(300px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const CatalogListing = ({ title, fluid, onClick }) => {
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 2, tension: 350, friction: 40 },
  }))

  return (
    <animated.div
      className="flex-1 relative mx-2 max-w-150 h-full mx-auto box-shadow bg-gray-400 cursor-pointer border border-gray-200"
      onMouseMove={event => set({ xys: calc(event) })}
      onMouseDown={event => event.preventDefault()}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      onClick={onClick}
      style={{ transform: props.xys.interpolate(trans) }}
    >
      <div className="text-xl absolute bottom-1/6 z-10 w-full bg-white bg-opacity-50 px-1">
        <div className="font-header text-outline text-center">
          <Markdown>{title}</Markdown>
        </div>
      </div>
      <Img
        className="w-full h-full"
        imgStyle={{ objectFit: "cover" }}
        fluid={fluid}
      />
    </animated.div>
  )
}

export default CatalogListing
